*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1{
  color: rgb(206 178 102);
  font-family: 'Bruno Ace', cursive;
}

h2{
  color: #ecece1;
  font-family: 'Bruno Ace', cursive;
}
p{
  color: #ecece1;
  font-family: 'Playfair Display', serif;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

a{
  text-decoration: none;
  color: black;


}

a:hover{
  color: #fff;

  
}